import * as React from "react";
import {createTheme, ThemeProvider, AppBar, Box, Link, Toolbar, Typography} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#212738"
        },
        secondary: {
            main: "#F97068"
        }
    }
});
const AppLayout: React.FC = ({children}) => {
    return (<ThemeProvider theme={theme}>
        <header><AppBar>
            <Toolbar style={{display:"flex", justifyContent:"space-between"}}>
                <Typography variant="h6">
                    <Link color="#fff" underline="none" href="/">VenApps</Link>
                </Typography>
                <Typography>
                    <Link color="#fff" underline="none" href="/">Home</Link>
                </Typography>
            </Toolbar>
    </AppBar></header>
            <Box mt={9} />
        {children}
        <footer><AppBar elevation={0} position="relative" color="transparent">
            <Toolbar style={{display:"flex", justifyContent:"space-between"}}>
                <Typography>Copyright © 2021 VenApps. All Rights Reserved</Typography>
                <Typography>
                    <Link underline="hover" href="/privacy_policy">Privacy</Link>
                </Typography>
            </Toolbar>
    </AppBar></footer>
    </ThemeProvider>);
}

export default AppLayout;
